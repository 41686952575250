
























































































































































import { Component, Vue } from "vue-property-decorator";
import { AppUser } from "@/models/user";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import Notifier from "@/helpers/notifier";
import _axios from "@/plugins/axios";
import { ApplicationStatus } from "@/models/businessApplication";
import agent from "@/helpers/agent";
import Portfolio from "@/models/portfolio";
import { namespace } from "vuex-class";
import * as _ from "lodash";

const app = namespace("app");

@Component
export default class OrganizationsView extends Vue {
  @app.Getter role!: string;
  @app.Getter user!: AppUser;

  private portfolios: Portfolio[] = [];
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 10,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";

  idcopies: Portfolio[] = [];
  columns = [
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: Portfolio) => row.name,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Reference",
      required: true,
      label: "Reference",
      align: "left",
      field: (row: Portfolio) => row.reference,
      sortable: true,
    },
    {
      name: "Status",
      required: true,
      label: "Status",
      align: "left",
      field: (row: Portfolio) => row.status,
      format: (val: string) => `${val}`,
      sortable: false,
    },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];

  get isUser() {
    return this.role === "User";
  }
  get isManager() {
    return this.role === "Manager";
  }
  get isReviewer() {
    return this.role === "Reviewer";
  }
  get isApprover() {
    return this.role === "SuperAdmin";
  }
  showApprove(status: ApplicationStatus) {
    if (status === ApplicationStatus.Rejected) return true;
    if (this.user.role === "User") {
      return status === ApplicationStatus.Pending;
    } else if (this.user.role === "Manager") {
      return status === ApplicationStatus.PendingReview;
    } else if (this.user.role === "Reviewer") {
      return status === ApplicationStatus.SubmittedToCentralBank;
    } else {
      return status === ApplicationStatus.Reviewed;
    }
  }

  lockedIcon(row: AppUser): string {
    return row.lockoutEnd === null ? "lock" : "lock_open";
  }

  status(status: ApplicationStatus) {
    return _.startCase(ApplicationStatus[status]);
  }
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  rowClick(row: Portfolio) {
    this.$router.push({
      name: "AddPortfolio",
      query: { id: row.portfolioApplicationID },
    });
  }
  submit(id: string) {
    const portfolio = this.portfolios.find(
      (x) => x.portfolioApplicationID == id
    );
    switch (this.role) {
      case "User":
        agent.PortFolios.submitToManager(id)
          .then(() => {
            const index = this.portfolios.findIndex(
              (x) => x.portfolioApplicationID === id
            );
            this.portfolios[index].status = ApplicationStatus.PendingReview;
            Notifier.showPositive(
              "Application submitted to manager for approval"
            );
          })
          .catch(() => {
            Notifier.showError("There was an error submitting error.");
          });
        break;
      case "Manager":
        agent.PortFolios.submitForReview(id)
          .then(() => {
            const index = this.portfolios.findIndex(
              (x) => x.portfolioApplicationID === id
            );
            this.portfolios[index].status =
              ApplicationStatus.SubmittedToCentralBank;
            Notifier.showPositive("Application submitted to Central Bank");
          })
          .catch(() => {
            Notifier.showError("There was an error submitting error.");
          });
        break;
      case "Reviewer":
        agent.PortFolios.submitForApproval(id)
          .then(() => {
            const index = this.portfolios.findIndex(
              (x) => x.portfolioApplicationID === id
            );
            this.portfolios[index].status = ApplicationStatus.Reviewed;
            Notifier.showPositive("Application submitted for approval");
          })
          .catch(() => {
            Notifier.showError("There was an error submitting error.");
          });
        break;

      default:
        this.$router.push({
          path: "/approve-portfolio",
          query: {
            id,
            reference: portfolio?.reference,
          },
        });
        break;
    }
  }

  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.PortFolios.list(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.portfolios = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "FormPortfolio",
      query: { id, step: "1" },
    });
  }
  exportData() {
    const token = this.$store.getters["app/getToken"];
    return _axios
      .get(`api/${ApiEndPoints.financialInstitutions}/export-to-excel`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = "Portfolios.xlsx";
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }
  deleteApplication(id: string) {
    agent.PortFolios.delete(id).then(() => {
      const index = this.portfolios.findIndex(
        (i) => i.portfolioApplicationID == id
      );
      this.portfolios.splice(index, 1);
      Notifier.showInfo(`Successfully deleted`);
    });
  }
}
